import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';

const Modal = props => {
  const handleEscape = useCallback(event => {
    if (event.keyCode === 27) {
      props.onClose();
    }
  })

  useEffect(() => {
    document.addEventListener("keydown", handleEscape, false);

    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    }
  }, [])

  return (
    <div className={`rt-modal ${props.open ? "rt-modal--open" : ""}`} onClick={(e) => e.target.classList.contains("rt-modal") && props.onClose()}>
      <button type="button" className="link-button rt-modal__close" onClick={props.onClose}>{crossIcon}</button>
      <div className={`container booking-modal-container rt-modal__container  ${props.wide && 'rt-modal__container--wide'} ${props.nopaddings && 'rt-modal__container--nopaddings'} ${props.timePopUp?"booking-modal-container__timePopUp":""}`}>
        {props.component}
      </div>
    </div>
  )
}

Modal.propTypes = {
  component: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
}

const crossIcon = (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.9966 1L1 40.866M1.00338 1.13398L41 41" stroke="white" strokeWidth="2"/>
  </svg>
)

export default Modal;