import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'

export default props => {
  const testimonials = props.testimonials.map((testimonial, i) => {
    return (
      <div
        key={testimonial._id.$oid}
        className={`we-are-hiring-page__testimonials-item ${i != 0 ? 'test-md__testimonial-hide' : 'test-md__testimonial'}`}
        data-item={Math.floor((i + 3) / 3)}
        data-mobile={i + 1}
      >
        <div className="we-are-hiring-page__testimonials-image">
          <img src={testimonial.avatar_url} className="we-are-hiring-page__testimonials-teacher-image"/>
        </div>
        <div className="we-are-hiring-page__testimonials-name">
          {testimonial.first_name}
        </div>
        <div className="we-are-hiring-page__testimonials-message">
          {testimonial.body}
        </div>
      </div>
    );
  });

  return (
    <ScrollContainer horizontal className="we-are-hiring-page__testimonials scroll-container">
      <div className="we-are-hiring-page__carousel-empty"></div>
      {testimonials}
      <div className="we-are-hiring-page__carousel-empty"/>
    </ScrollContainer>
  );
};