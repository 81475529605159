import React from 'react';
import Swipe from 'react-easy-swipe';
import $ from 'jquery';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReviewsCarousel from './ReviewsCarousel';
import starIcon from '../../../assets/images/rating-star.svg';
import starIconGray from '../../../assets/images/rating-star-gray.svg';

let controller = 0;
let activeSlide = false;
const swipeMouseDeltaRequired = 50;
let iterator = 0;

function show(blockNum) {
  $('.landing-page__who-we-are-carousel-item').addClass('d-none');
  $(`.landing-page__who-we-are-carousel-item[data-item=${blockNum}]`).removeClass('d-none');
}

function slideLeft() {
  controller -= 1;
  if(controller < 0) {
    controller = parseInt($('.landing-page__who-we-are-carousel-item:last-child').attr('data-item'));
  }
  show(controller);
  activeSlide = true;
}

function slideRight() {
  controller += 1;
    
  if(controller === parseInt($('.landing-page__who-we-are-carousel-item:last-child').attr('data-item')) + 1) {
    controller = 0;
  }
  show(controller);
  activeSlide = true;
}

class TeachersCarousel extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      isActive: false,
      currentTeacher: null,
    };

    this.onSwipeMove = this.onSwipeMove.bind(this);
  }

  activeUpdate () {
    this.setState(() => ({
      isActive: $(window).width() < '576'
    }));
  }

  componentDidMount () {
    this.activeUpdate();

    $(window).resize(() => {
      this.activeUpdate();
    });
  }

  onSwipeStart(event) {
  }

  onSwipeMove(position, event) {
    if (!activeSlide && this.state.isActive) {
      if (position.x < -swipeMouseDeltaRequired) {
        slideRight();
      }
      else if (position.x > swipeMouseDeltaRequired) {
        slideLeft();
      }
    }
  }

  onSwipeEnd(event) {
    activeSlide = false;
  }

  render() {
    const renderRating = (teacher) => {
      const rating = !!teacher?.reviews?.length ? teacher.reviews.map(review => review.star_rating).reduce((a, b) => (a + b)) / teacher.reviews.length : null;

      return !!rating && 
        <div className="align-items-center landing-page__who-we-are-carousel-item-rating mt-4">
          {
            Array.from(Array(5).keys()).map(index => (
              <img key={index} className="review-card__rating-star" src={index >= Math.round(rating / 2) ? starIconGray : starIcon} />
            ))
          }
          <p className="review-card__ratingn m-0">{Math.round(rating / 2)}/5</p>
        </div>
    }

    const teachers = this.props.data.map((teacher, i) => {
      let displayable = i < 4;
      let dataItem = i / 4 >> 0;
      return (
        <div 
          onClick={() => {
            this.setState({ currentTeacher: teacher });
          }}
          className={`landing-page__who-we-are-carousel-item ${this.state.currentTeacher === teacher ? 'landing-page__who-we-are-carousel-item--current' : ''} ${!displayable && this.state.isActive ? "d-none" : ""}`} data-item={dataItem} key={++iterator}>
          <div className="landing-page__who-we-are-image-wrapper">
            <img className="landing-page__who-we-are-image" src={teacher.avatar_url}></img>
          </div>
          <div className="landing-page__who-we-are-section__name">
            {teacher.first_name}
          </div>
          <img className="landing-page__who-we-are-book-icon landing-page__who-we-are-book-icon--mobile" src="/assets/book-f45aed16132938a458a3e91b8dc195c9ae3f959cfff0a8e9aac1d9f28a26af59.svg"></img>
          <img className="landing-page__who-we-are-book-icon landing-page__who-we-are-book-icon--desktop " src="/assets/translation-icon-8c835937069199cc101e32c861e6ddcce0b541f8b0600d9748b2bc95a2ecccab.svg"></img>
          <span className="landing-page__who-we-are-section__language">
            {teacher.teaches_list.join(', ')}
          </span>
          {renderRating(teacher)}
        </div>
      )
    });

    return (
      <>
        <Swipe
          onSwipeStart={this.onSwipeStart}
          onSwipeMove={this.onSwipeMove}
          onSwipeEnd={this.onSwipeEnd}>
          <ScrollContainer horizontal className="scroll-container landing-page__who-we-are-carousel row">
            <div className="landing-page__carousel-empty"></div>
            {teachers}
            <div className="landing-page__carousel-empty"></div>
          </ScrollContainer>
        </Swipe>
        {
          !!this.state.currentTeacher?.reviews?.length  &&
            <ReviewsCarousel reviews={this.state.currentTeacher.reviews} />
        }
      </>
    );
  }
}


export default TeachersCarousel;

