import { EVENTS } from "../config/events";

$(document).ready(() => {
  EVENTS.forEach(eventName => {
    $(`[data-trigger="${eventName}"]`).click(e => {
      const detail = $(e.target).data("detail");

      const event = new CustomEvent(eventName, {
        detail: detail ? JSON.parse(detail) : null
      });

      window.dispatchEvent(event);
    });
  });
});
