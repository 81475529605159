import React, { useState, useEffect } from 'react';
import I18n from "../../i18n-js/index.js.erb";
import Button from "./Button";
import PropTypes from "prop-types";
import celebrationIcon from '../../../assets/images/confetti.svg';
import tokkyAerostat from '../../../assets/images/tokky-aerostat.svg';
import tokkyAirship from '../../../assets/images/tokky-airship.svg';
import tokkyBallon from '../../../assets/images/tokky-ballon.svg';
import tokkyBallons from '../../../assets/images/tokky-ballons.svg';
import ScrollContainer from 'react-indiana-drag-scroll';

const CURRENCY = {
  USD: 'Dollars',
  CHF: 'SFranc',
  EUR: 'Euros'
}

const CURRENCY_SYMBOL = {
  Dollars: '$',
  SFranc: '₣',
  Euros: '€'
}

const PRICING_DATA = {
  Dollars: 'price_usd',
  SFranc: 'price_chf',
  Euros: 'price_eur'
}

const PlansChoice = props => {

  console.log("====props", props);
  const [monthlyPlan, freeformPlan, groupPlan] = props.data.items;


  const [choosenProgram, setChoosenProgram] = useState();
  const [choosenProgramType, setChoosenProgramType] = useState('individual');

  // TODO: handle CHF at the first state
  const [choosenCurrency, setChoosenCurrency] = useState(CURRENCY[props.currency] || 'Euros');
  const [choosenPlanObject, setChoosenPlanObject] = useState(monthlyPlan);
  const [choosenProgramObject, setChoosenProgramObject] = useState(null);

  useEffect(() => {
    props.onChange(choosenPlanObject, choosenProgramObject, choosenCurrency);
  }, [choosenPlanObject, choosenProgramObject, choosenCurrency])

  const handleProgramChoice = (program, index) => {
    props.handleButtonActiveChange && props.handleButtonActiveChange(true);
    setChoosenProgramObject({...program, weeks: index === 0 ? 4 : 12});
    setChoosenProgram(program.value);
  }

  const programContent = [
    {type: "Monthly", images: [tokkyBallon, tokkyBallons], planLength: " / 4 "+I18n.t("plans.weeks")},
    {type: "Freeform", images: [tokkyAirship, tokkyAerostat], planLength: " / 12 "+I18n.t("plans.weeks")},
    {type: "Group", images: [tokkyAerostat, tokkyAirship], planLength: " / "+I18n.t("plans.package")}
  ];

  const renderProgram = (program, index, prIndex) => {
    const buttonLabel = (symbol, price, planLength) => {
      return symbol + price  + planLength
    }

    const wrapperClass = () => {
      if (choosenProgramType === "group") {
        return "choose-your-plan__program--group"
      }
      return programContent[index].type === "Monthly" ? "choose-your-plan__program--monthly" : "choose-your-plan__program--freeform"
    }

    return (
      <div key={program.value} className={`choose-your-plan__program px-0 ${wrapperClass()}`}>
        <input
          required
          className="choose-your-plan__input"
          type="radio" id={`plan_${program.value}`}
          name="program"
          value={program.value}
          checked={program.value === choosenProgram}
          onChange={() => { handleProgramChoice(program, index) }}
        />
        <div className="choose-your-plan__program-label">
          <img className="choose-your-plan__program-icon" src={programContent[index].images[prIndex]} />
          {
            choosenProgramType === 'group' ?
              <h1 className="u-txt--center text-nowrap choose-your-plan__program-lessons-label"><strong className="choose-your-plan__program-lessons-label choose-your-plan__program-lessons-label--number">{program.lessons_number}</strong> {I18n.t("plans.classes")}</h1>
            : <h1 className="u-txt--center text-nowrap choose-your-plan__program-lessons-label"><strong className="choose-your-plan__program-lessons-label choose-your-plan__program-lessons-label--number">{program.lessons_number}</strong> {I18n.t("plans.lessons")}</h1>
          }
          <div className="d-flex justify-content-center">
            <div className="choose-your-plan__button-field">
            {choosenCurrency === "Dollars" ?
              <Button
                // primary
                fullWidth
                link={props.link}
                url={props.url}
                isPlan={true}
                firstPlanPart={"$" + program.price_usd}
                secondPlanPart={programContent[index].planLength}
                onClick={() => handleProgramChoice(program, index)}
                label={buttonLabel('$', program.price_usd, programContent[index].planLength)}
              /> : choosenCurrency === "SFranc" ?
              <Button
                // primary
                fullWidth
                link={props.link}
                url={props.url}
                isPlan={true}
                firstPlanPart={"₣" + program.price_chf}
                secondPlanPart={programContent[index].planLength}
                onClick={() => handleProgramChoice(program, index)}
                label={buttonLabel('₣', program.price_chf, programContent[index].planLength)}
              /> :
              <Button
                // primary
                fullWidth
                link={props.link}
                url={props.url}
                isPlan={true}
                firstPlanPart={"€" + program.price_eur}
                secondPlanPart={programContent[index].planLength}
                onClick={() => handleProgramChoice(program, index)}
                label={buttonLabel('€', program.price_eur, programContent[index].planLength)}
            /> }

            </div>
          </div>
          { choosenProgramType === 'group' ? <div className="u-txt--center choose-your-plan__per-lesson">{I18n.t("plans.students_per_group")} </div>
            : choosenProgramType !== 'group' && choosenCurrency === "Dollars" ?
            <div className="u-txt--center choose-your-plan__per-lesson">{I18n.t("plans.one_lesson")} {"$"} {Math.round(100 * program.price_usd / program.lessons_number) / 100} {I18n.t("plans.per_lesson")}</div>
            : choosenProgramType !== 'group' && choosenCurrency === "SFranc" ?
            <div className="u-txt--center choose-your-plan__per-lesson">{I18n.t("plans.students_per_group")} {"₣"} {Math.round(100 * program.price_chf / program.lessons_number) / 100} {I18n.t("plans.per_lesson")} </div>
            : choosenProgramType !== 'group' &&
            <div className="u-txt--center choose-your-plan__per-lesson">{I18n.t("plans.one_lesson")} {"€"} {Math.round(100 * program.price_eur / program.lessons_number) / 100} {I18n.t("plans.per_lesson")} </div>
          }
        </div>
      </div>
    )
  }

  return (
    <div className="col-12 col-md-12 col-lg-12 mx-auto px-0">
      <div className={`choose-your-plan mt-md-4 ${props.onboarding ? "" : "mt-0 mb-5 "} `}>
        <h1 className="choose-your-plan__label u-txt--center">{I18n.t("plans.choose_your_plan")}</h1>
        <div className="d-flex justify-content-center"><div className="choose-your-plan__subtitle text-center"><span className="text-md-nowrap">{I18n.t("plans.we_are_not_1")}</span><br/>{I18n.t("plans.we_are_not_2")}</div></div>
        <div className="row d-flex justify-content-center">
        <div className={`d-flex choose-your-plan__buttons`} >
          <button
            onClick={() => setChoosenCurrency("Dollars")}
            className={`button choose-your-plan__button
              ${choosenCurrency==="Dollars" ? "choose-your-plan__button--active" : ""}`
            }
          > USD
          </button>
          <button
            onClick={() => setChoosenCurrency("Euros")}
            className={`button choose-your-plan__button
              ${choosenCurrency==="Euros" ? "choose-your-plan__button--active" : ""}`
            }
          > EUR
          </button>
          <button
            onClick={() => setChoosenCurrency("SFranc")}
            className={`button choose-your-plan__button
              ${choosenCurrency==="SFranc" ? "choose-your-plan__button--active" : ""}`
            }
          > CHF
          </button>
        </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className={`d-flex choose-your-plan__buttons`} >
            <button
              onClick={() => setChoosenProgramType("individual")}
              className={`button choose-your-plan__button
                ${choosenProgramType==="individual" ? "choose-your-plan__button--active" : ""}`
              }
            > {I18n.t(`components.knowledge_levels.individual`)}
            </button>
            <button
              onClick={() => setChoosenProgramType("group")}
              className={`button choose-your-plan__button
                ${choosenProgramType==="group" ? "choose-your-plan__button--active" : ""}`
              }
            > {I18n.t(`components.knowledge_levels.group`)}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {choosenProgramType === 'individual' ? <ScrollContainer horizontal className="scroll-container landing-page__plan-select-carousel row">
              {[monthlyPlan, freeformPlan].map( (item, index) => item.programs.map((program, prIndex) => renderProgram(program, index, prIndex)))}
            </ScrollContainer> :
            <ScrollContainer horizontal className="scroll-container landing-page__plan-select-carousel row justify-content-center">
              {[groupPlan].map( (item, index) => item.programs.map((program, prIndex) => renderProgram(program, 2, prIndex)))}
            </ScrollContainer>
            }

            {props.onboarding ||
              <div className={`d-flex choose-your-plan__article ${choosenProgramType === 'group' ? 'justify-content-center' : ''}`}>
                <img src={celebrationIcon} className="choose-your-plan__icon" />
                { choosenProgramType === 'group' ?
                  <span className="ml-3 choose-your-plan__each-lesson">{I18n.t("plans.each_group_class")} <strong>{I18n.t("onboarding.50_minutes_long")}</strong></span>
                : <span className="ml-3 choose-your-plan__each-lesson">{I18n.t("plans.each_lesson.1_part")} <strong>{I18n.t("onboarding.25_minutes")}{I18n.t("plans.each_lesson.2_part")}</strong> {I18n.t("onboarding.50_minute")}{I18n.t("plans.each_lesson.3_part")}</span>}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

PlansChoice.propTypes = {
  data: PropTypes.object,
  link: PropTypes.bool,
  url: PropTypes.string,
  onChange: PropTypes.func
}

PlansChoice.defaultProps = {
  onChange: () => { }
}

export default PlansChoice;
