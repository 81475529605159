import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import TeacherInfo from '../onboarding/booking/TeacherInfo';
import { SHOW_TEACHER_MODAL_EVENT } from '../../config/events';

const TeacherModal = props => {
  const [show, setShow] = useState(props.open);

  window.addEventListener(SHOW_TEACHER_MODAL_EVENT, (e) => {
    if (!e.detail || e.detail == props.teacherInfo.teacherId) {
      setShow(true)
    }
  });

  return (
    <Modal
      component={
        <TeacherInfo info={{ ...props.teacherInfo, avatar: props.teacherInfo.avatar_url, educations: props.teacherInfo.education }} />
      }
      key={props.teacherInfo.id}
      open={!!props.teacherInfo && show}
      onClose={() => setShow(false)}
    />
  )
}

TeacherModal.propTypes = {
  teacherInfo: PropTypes.shape({
    avatar_url: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    teaches: PropTypes.arrayOf(PropTypes.string),
    speaks: PropTypes.arrayOf(PropTypes.string),
    experiences: PropTypes.arrayOf(PropTypes.shape({
      position: PropTypes.string,
      place_of_work: PropTypes.string,
      date_of_beginning: PropTypes.string,
      date_of_end: PropTypes.string
    })),
    education: PropTypes.arrayOf(PropTypes.shape({
      degree: PropTypes.string,
      institution: PropTypes.string
    })),
    id: PropTypes.string
  }),
  open: PropTypes.bool
}

TeacherModal.defaultProps = {
  open: false
}

export default TeacherModal;
