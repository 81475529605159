import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const SEVERITIES = {
  alert: 'error',
  notice: 'info'
}

const InfoPopup = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const data = typeof props.data === 'string' ? { message: props.data } : props.data;

  console.log("====data", data)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={ SEVERITIES[props.type] }>
          {data.title && <h2 dangerouslySetInnerHTML={{ __html: data.title }}></h2>}
          {data.message && <span dangerouslySetInnerHTML={{ __html: data.message }}></span>}
        </Alert>
      </Snackbar>
    </div>
  );
}

InfoPopup.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string
    }),
    PropTypes.string
  ]),
  type: PropTypes.oneOf(['alert', 'notice'])
}

export default InfoPopup;
