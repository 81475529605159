import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '../UI/Button';

const MovidaMaya = props => {
  const [donateCount, writeDonateCount] = useState(1);
  const setDonateCount = count => {
    if ((count > 0) && (count <= props.available_stars)) {
      writeDonateCount(count);
    }
  };
  const incrementDonateCount = () => setDonateCount(donateCount + 1);
  const decrementDonateCount = () => setDonateCount(donateCount - 1);

  let starsArray = [];
  for (let i = 0; i < props.available_stars; i++) {
    starsArray.push(i);
  }


  return (
    <div className="donation__movida movida">
      <div className="movida__block movida__block--left">
        <div className="movida__photos-grid-wrapper">
        <div className="movida__photos-grid-container">
          <div className="movida__photo6"></div>
          <div className="movida__photo1"></div>
          <div className="movida__photo2"></div>
          <div className="movida__photo3"></div>
          <div className="movida__photo4"></div>
          <div className="movida__photo5"></div>
        </div>
        </div>
      </div>
      <div className="movida__block movida__block--right">
        <p className="movida__donate-to">DONATE TO</p>
        <p className="movida__title">Movida Maya</p>
        <p className="movida__body">Rootalky has partnered with a fantastic NGO based in Tulum, Mexico on the Yucatan Peninsula called Movida Maya.<br/><br/> Their mission is to increase the capacity of indigenous youth, whilst preserving their cultural identity, to build a sustainable future for themselves and contribute to the livelihood of their communities.</p>
        <a className="movida__link" href="https://www.movidamaya.org/">movidamaya.org</a>
        <div className="donation__dancing-stars">
          {
            starsArray.map((i) => (
              i < donateCount 
                ? <div className="donation__dancing-star donation__dancing-star--active" key={i}></div>
                : <div className="donation__dancing-star" key={i}></div>
            ))
          }
        </div>
        <div className="movida__body-bottom">
          <p className="stars-count-selector__input-value">
            {donateCount}
            <span className="stars-count-selector__input-value--additional">
              /{props.available_stars}
            </span>
          </p>
          <div className="donation__stars-count-selector stars-count-selector">
            <button
              type="button"
              disabled={donateCount == 1}
              onClick={decrementDonateCount}
              className="stars-count-selector__btn"
            >-</button>
            <input
              type="number"
              name="donation_program[stars_count]"
              value={donateCount}
              min={1}
              max={props.available_stars}
              onChange={e => setDonateCount(e.target.value)}
              className="stars-count-selector__input"
            ></input>
            <button
              type="button"
              disabled={donateCount == props.available_stars}
              onClick={incrementDonateCount}
              className="stars-count-selector__btn"
              >+</button>
          </div>
  
          <Button submit fullWidth label={`Donate ${donateCount} Star${donateCount > 1 ? 's' : ''}`} />
        </div>
      </div>
    </div>
  );
}

MovidaMaya.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image_url: PropTypes.string,
  raised_money: PropTypes.number,
  target_money: PropTypes.number,
  available_stars: PropTypes.number
}

export default MovidaMaya;