let userInput = false;
let queCounter= 0;

$(document).ready(() => {
  $(".faq-page__question-box").click((e) => {
    $(
      `.faq-page__answer[data-num=${$(e.target).attr("data-num")}]`
    ).toggleClass("d-flex");

    $(`.faq-page__sign[data-num=${$(e.target).attr("data-num")}]`).toggleClass(
      "faq-page__sign--active"
    );
  });

  $(".faq-page__nav-box").click((e) => {
    $(".faq-page__search-box").removeClass("d-flex");
    if ($(e.target).attr("data-category") === "search") {
      $('.faq-page__search-box[data-category="search"]').addClass("d-flex");
    }
    $(".faq-page__question-box").addClass("d-none");
    $(".faq-page__nav-box-description").removeClass(
      "faq-page__nav-box-description--active"
    );
    $(
      `.faq-page__nav-box-description[data-category=${$(e.target).attr(
        "data-category"
      )}]`
    ).addClass("faq-page__nav-box-description--active");
    $(`.faq-page__nav-box-pic--active`).addClass("d-none");
    $(`.faq-page__nav-box-pic--passive`).removeClass("d-none");
    $(
      `.faq-page__nav-box-pic--passive[data-category=${$(e.target).attr(
        "data-category"
      )}]`
    ).addClass("d-none");
    $(
      `.faq-page__nav-box-pic--active[data-category=${$(e.target).attr(
        "data-category"
      )}]`
    ).removeClass("d-none");
    if ($(e.target).attr("data-category") === "popular") {
      $(".faq-page__question-box").removeClass("d-none");
    } else {
      $(
        `.faq-page__question-box[data-category=${$(e.target).attr(
          "data-category"
        )}]`
      ).removeClass("d-none");
    }
  });

  $(".faq-page__nav-box[data-category=popular]").trigger("click");

  $(".faq-page__custom-question-input").on("input", () => {
    userInput = true;
    $(".faq-page__custom-question-button").addClass(
      "faq-page__custom-question-button--active"
    );

    if ($(".faq-page__custom-question-input").val().length === 0) {
      userInput = false;
      $(".faq-page__custom-question-button").removeClass(
        "faq-page__custom-question-button--active"
      );
    }
  });

  $(".faq-page__custom-question-button").click(() => {
    if (userInput) {
      // button is clickable: some code goes here
    } else return false;
  });

  $(".faq-page__text-input").focus(() => {
    $(".faq-page__search-input").addClass("faq-page__search-input--focus");
  });

  $(".faq-page__text-input").blur(() => {
    $(".faq-page__search-input").removeClass("faq-page__search-input--focus");
  });

  $(".faq-page__text-input").on("keyup", () => {
    queCounter = 0;
    $(".faq-page__search-image").addClass("d-none");
    $('.faq-page__search-results').removeClass('d-none');
    if ($(".faq-page__text-input").val().length === 0) {
      $(".faq-page__search-image").removeClass("d-none");
      $(`.faq-page__question-box`).addClass("d-none");
      $('.faq-page__search-results').addClass('d-none');
    } else {
      $(".faq-page__question-text").each(function () {
        if (
          $(this)
            .text()
            .toLowerCase()
            .includes($(".faq-page__text-input").val().toLowerCase())
        ) {
          queCounter += 1;
          $(
            `.faq-page__question-box[data-num=${$(this).attr("data-num")}]`
          ).removeClass("d-none");
        } else {
          $(
            `.faq-page__question-box[data-num=${$(this).attr("data-num")}]`
          ).addClass("d-none");
        }
      });
      $('#search-results').text(queCounter);
    }
  });
});
