function cc_format(value) {
  var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  var matches = v.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];
  for (i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(" ");
  } else {
    return value;
  }
}

$(document).ready(() => {
  $("#credit_card_verification_value").attr("maxLength", "3");
  $("#credit_card_number").attr("maxLength", "19");
  $("#credit_card_expiry_date").attr("maxLength", "5");

  $("#credit_card_number").on("input", () => {
    $("#credit_card_number").val(cc_format($("#credit_card_number").val()));
  });

  $("#credit_card_expiry_date").keyup(e => {
    if (e.keyCode != 8) {
      if ($("#credit_card_expiry_date").val().length == 2) {
        $("#credit_card_expiry_date").val(
          $("#credit_card_expiry_date").val() + "/"
        );
      } else if ($("#credit_card_expiry_date").val().length == 5) {
        return false;
      }
    } else {
      var temp = $("#credit_card_expiry_date").val();

      if ($("#credit_card_expiry_date").val().length == 5) {
        $("#credit_card_expiry_date").val(temp.substring(0, 4));
      } else if ($("#credit_card_expiry_date").val().length == 2) {
        $("#credit_card_expiry_date").val(temp.substring(0, 1));
      }
    }
  });
});
