import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import I18n from '../../i18n-js/index.js.erb';
import defaultAvatar from '../../../assets/images/fallback/default-avatar.png';

export default props => {
  const testimonials = props.testimonials.map((testimonial) => {
    return (
      <div className="course-page__testimonials-box" key={testimonial._id.$oid}>
        <div className="course-page__testimonials-box-pic">
          <img
            src={testimonial.student_avatar_url || defaultAvatar}
            className="course-page__testimonial-pic"
          />
        </div>
        <h3 className="course-page__testimonial-name">
          {
            testimonial.student
              ? testimonial.student.full_name
              : testimonial.student_name
          }
        </h3>
        <span className="course-page__testimonial-taught">
          {I18n.t('pages.french_course.testimonial.taught_by')}
          <span className="course-page__testimonial-teacher">
            {!!testimonial.teacher && ` ${testimonial.teacher.first_name} ${testimonial.teacher.last_name}`}
          </span>
        </span>
        <span className="course-page__testimonial-learning-date">
          {I18n.t('pages.french_course.testimonial.learning_date', {learning_date: testimonial.learning_date})}
        </span>
        <span className="course-page__testimonial-last-comment">
          {I18n.t('pages.french_course.testimonial.commented_on', {created: testimonial.created})}
        </span>
        <span className="course-page__testimonial-info">
          {testimonial.body}
        </span>
        <span className="course-page__testimonial-subinfo"></span>
      </div>
    );
  });

  return (
    <ScrollContainer horizontal className="course-page__testimonials scroll-container">
      <div className="course-page__carousel-empty">
      </div>
      {testimonials}
      <div className="course-page__carousel-empty"/>
    </ScrollContainer>
  );
};