import React from 'react';

export default props => {
  if (props.link) {
    return (
      <a
        href={props.url}
        className={`button button--link
          ${props.primary ? "button--primary" : "button--secondary"}
          ${props.next ? "button--next" : ""}
          ${props.back ? "button--back" : ""}
          ${props.cta ? "button--cta" : ""}
          ${props.continue ? "button--continue" : ""}
          ${props.fullWidth ? "button--full-width" : ""}
          ${props.disabled ? "button--disabled" : ""}
          ${props.hidden ? "d-none" : ""}
          ${props.text ? "button--text" : ""}
          ${props.isPlan? "button--plan" : ""}
          ${props.className}`
        }
      >
        {props.iconLeft && arrowLeftIcon}
        {props.isPlan || props.label}
        {props.isPlan && <div><strong>{props.firstPlanPart}</strong> {props.secondPlanPart}</div>}
        {props.iconRight && arrowRightIcon}
      </a>
    )
  }
  return (
    <button
      onClick={props.onClick}
      type={props.submit ? "submit" : "button"}
      className={`button
        ${props.primary ? "button--primary" : "button--secondary"}
        ${props.next ? "button--next" : ""}
        ${props.back ? "button--back" : ""}
        ${props.cta ? "button--cta" : ""}
        ${props.continue ? "button--continue" : ""}
        ${props.fullWidth ? "button--full-width" : ""}
        ${props.disabled ? "button--disabled" : ""}
        ${props.hidden ? "d-none" : ""}
        ${props.text ? "button--text" : ""}
        ${props.isPlan? "button--plan" : ""}
        ${props.className}`
      }
      disabled={props.disabled}
    >
      {props.iconLeft && arrowLeftIcon}
      {props.isPlan || props.label}
      {props.isPlan && <div><strong>{props.firstPlanPart}</strong> {props.secondPlanPart}</div>}
      {props.iconRight && arrowRightIcon}
    </button>
  )
}

const arrowLeftIcon = (
  <svg className="button__icon button__icon--left" width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.292892 7.2929C-0.0976315 7.68342 -0.0976314 8.31658 0.292893 8.70711L6.65686 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928933C7.68054 0.538409 7.04738 0.538409 6.65685 0.928933L0.292892 7.2929ZM21 7L1 7L1 9L21 9L21 7Z" fill="black"/>
  </svg>
)

const arrowRightIcon = (
  <svg className="button__icon button__icon--right" width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.7071 8.47255C21.0976 8.08202 21.0976 7.44886 20.7071 7.05833L14.3431 0.694373C13.9526 0.303848 13.3195 0.303849 12.9289 0.694373C12.5384 1.0849 12.5384 1.71806 12.9289 2.10859L18.5858 7.76544L12.9289 13.4223C12.5384 13.8128 12.5384 14.446 12.9289 14.8365C13.3195 15.227 13.9526 15.227 14.3431 14.8365L20.7071 8.47255ZM8.74228e-08 8.76544L20 8.76544L20 6.76544L-8.74228e-08 6.76544L8.74228e-08 8.76544Z" fill="black"/>
  </svg>
)
