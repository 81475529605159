import React from 'react';
import I18n from "../../i18n-js/index.js.erb";
import starIcon from '../../../assets/images/rating-star.svg';
import starIconGray from '../../../assets/images/rating-star-gray.svg';
import paginationArrowIcon1 from '../../../assets/images/pagination-arrow-1.svg';
import paginationArrowIcon2 from '../../../assets/images/pagination-arrow-2.svg';


let transform = "translateX(50px)";

class CommentsCarousel extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      activePage: 0,
    };
  }

  setActivePage (page) {
    this.setState({ activePage: page });
  }

  setPrevPage () {
    this.setState(({
      activePage: Math.max(this.state.activePage - 1,0),
    }));
  }

  setNextPage (page) {
    this.setState(({
      activePage: Math.min(this.state.activePage + 1, 3),
    }));
  }

  componentDidUpdate () { }

  render() {
    const reviews = [...this.props.reviews.slice(0, 16), ...Array(16 - this.props.reviews.slice(0, 16).length)].map((review, index) => {
      return (
        <div key={index} className="col-4">
        {
          review &&
            <div>
              <div className="landing-page__user-comments-card-text">
                <h3 className="review-card__title">{review.title || 'Review'}</h3>
              </div>
              <div className="d-flex align-items-center">
                {
                  Array.from(Array(5).keys()).map(index => (
                    <img key={index} className="review-card__rating-star" src={index >= review.star_rating ? starIconGray : starIcon} />
                  ))
                }
                <p className="review-card__rating">{review.star_rating}/5</p>
              </div>

            <div>
              <div className="landing-page__user-comments-student">{review.body}</div>
              <p className="review-card__timestamp">{review.student_name} <span className="u-cs-txt--gray">|</span> {new Date(review.created_at).toDateString()}</p>
            </div>
          </div>
        }
        </div>
      )
    });

    return (
        <div className="container">
          <div className="landing-page__user-comments-title">
            {I18n.t("pages.landing.reviews.title")}
          </div>
        <div className="landing-page__user-comments-subtitle">
          {I18n.t("pages.landing.reviews.subtitle")}
        </div>
        <div className="row landing-page__user-comments-cards-container">
          <div className={`landing-page__user-comments-cards landing-page__user-comments-cards--${this.state.activePage}`}>
            {reviews}
          </div>
        </div>
        <div className="landing-page__user-comments-cards-pagination">
          <button className="landing-page__user-comments-cards-arrow-pagination-container" onClick={()=> this.setPrevPage()}>
            <img className="landing-page__user-comments-cards-left-arrow-pagination" src={paginationArrowIcon2}></img>
          </button>
          <button className={`landing-page__user-comments-cards-page-pagination ${this.state.activePage == 0 ?"landing-page__user-comments-cards-page-pagination--active" : ""}`} onClick={()=> this.setActivePage(0)}></button>
          <button className={`landing-page__user-comments-cards-page-pagination ${this.state.activePage == 1 ?"landing-page__user-comments-cards-page-pagination--active" : ""}`} onClick={()=> this.setActivePage(1)}></button>
          <button className={`landing-page__user-comments-cards-page-pagination ${this.state.activePage == 2 ?"landing-page__user-comments-cards-page-pagination--active" : ""}`} onClick={()=> this.setActivePage(2)}></button>
          <button className={`landing-page__user-comments-cards-page-pagination ${this.state.activePage == 3 ?"landing-page__user-comments-cards-page-pagination--active" : ""}`} onClick={()=> this.setActivePage(3)}></button>
          <button className="landing-page__user-comments-cards-arrow-pagination-container" onClick={()=> this.setNextPage()}>
            <img className="landing-page__user-comments-cards-right-arrow-pagination" src={paginationArrowIcon1}></img>
          </button>
        </div>
      </div>
    );
  }
}

export default CommentsCarousel;