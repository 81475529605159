import Splide from '@splidejs/splide';
import { Video } from '@splidejs/splide-extension-video';
import AOS from "./libs/aos";

$(document).ready(function() {
  // Listen for the click event on dropdown items
  $('.landing-page-2023 .get_started_container .dropdown-item').on('click', function(e) {
    e.preventDefault();

    // Get the selected value from the clicked item's data-value attribute
    var selectedLanguage = $(this).data('language');
    var classFlag = $(this).data('class');

    // Set the button text to the selected value
    $('span.languageDropdown').text(selectedLanguage);
    $('.get_started_container .select_result #target_flag').removeClass().addClass('flag-icon').addClass(classFlag)

    var hrefGetStarted = new URL($('.btn-get_started').attr('href'))
    $('.btn-get_started').attr('href', hrefGetStarted.href + `?language=${selectedLanguage}`)
  });

  $('.landing-page-2023 .languageI18n .dropdown-item').on('click', function(e) {
    e.preventDefault();
    // Get the selected value from the clicked item's data-value attribute
    var selectedLanguage = $(this).data('language');
    // Set the button text to the selected value
    $(this).parents('.languageI18n').find('span.languageI18n').text(selectedLanguage);

    const hostParts = location.host.split('.');
    const targetLang =  $(this).data('value');
    if (hostParts.length === 2) {
      if (targetLang != "en") {
        hostParts.unshift(targetLang);
      }
    } else {
      if (targetLang === "en") {
        hostParts.shift();
      }
      else {
        hostParts[0] = targetLang;

      }
    }
    const newHost = hostParts.join('.');
    location.host = newHost;
  });

  $('.landing-page-2023 .currency .dropdown-item').on('click', function(e) {
    e.preventDefault();
    // Get the selected value from the clicked item's data-value attribute
    var selectedLanguage = $(this).data('language');
    var classFlag = $(this).data('class');

    var selectedLanguage = $(this).data('language');
    // Set the button text to the selected value
    $(this).parents('.currency').find('span.currency').text(selectedLanguage);
    $(this).parents('.currency').find('.select_result #target_flag').removeClass().addClass('flag-icon').addClass(classFlag)
  });

  new Splide( '.teaching_experience-wrapper .splide', {
    type: 'loop',
    height     : '460px',
    arrows: false,
    breakpoints: {
      768: {
        width      : '375px',
        perPage    : 1,
        padding: '20px',
        autoplay: true,
        interval: 2500,
      }
    }
  } ).mount( { Video } );

  new Splide( '.tell-us-container .splide', {
    type: 'loop',
    arrows: false,
    gap: '1em',
    autoplay: true,
    interval: 2500,
    breakpoints: {
      768: {
        width      : '375px',
        perPage    : 1,
      }
    }
  } ).mount();

  new Splide( '.student-testimonials-container .splide', {
    type: 'loop',
    arrows: true,
    padding: '200px',
    focus: 'center',
    start: 2,
    gap: '1em',
    perPage: 1,
    autoplay: true,
    interval: 2500,
    breakpoints: {
      768: {
        arrows: false,
        width      : '375px',
        perPage    : 1,
        padding: '16px',
      }
    }
  } ).mount();

  console.log('hi')

  AOS.init({
    duration: 500,
  })
});

