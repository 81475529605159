$(document).ready(() => {
  $('#nav-button').click(() => {
    $('body').addClass('noscroll-y');
    $('.navigation__content').addClass('d-block');
  });

  $('.close-mobile-nav-cross').click(() => {
    $('body').removeClass('noscroll-y');
    $('.navigation__content').removeClass('d-block');
  });
});