import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../../i18n-js/index.js.erb';

const TeacherInfo = props => {

  const renderInfoRow = (snippet, icon, rowInfo) => (
       <>
      <div className={`teacher-info__labels ${snippet === "teaches" || snippet === "speaks" ? "mb-3" : ""} col-12 col-sm-4 text-sm-right booking-text booking-text--medium booking-text--bold`}>
       <div className={`d-table-cell d-sm-inline text-right pr-1 ${snippet === 'teaches' ? 'ml-n1' : ''}`}> {icon}</div><span className="d-table-cell d-sm-inline align-top">{I18n.t("booking." + snippet)}</span>
      </div>
      <div className={`d-table-row d-sm-block ${snippet === "teaches" || snippet === "speaks" ? "mb-3" : ""} col-12 col-sm-8  booking-text booking-text--medium`}>
        <div className="d-table-cell d-sm-block"></div><div className="d-table-cell d-sm-block py-2 py-sm-0" >{rowInfo}</div>
      </div>
      </>
  )

  if (props.info == null) {
    return <></>
  }

  return (
    <div className="row teacher-info">
      <div className="col-md-3 col-12">
        <div className="col-7 col-sm-4 col-md-12 mx-auto mx-sm-0 px-0">
          <img
            src={props.info.avatar}
            className="teacher-info__image booking-teachers-image"/>
        </div>
      </div>
      <div className="col-md-9 col-12">
        <p className="u-txt--description mt-3 mt-md-0 text-center text-sm-left">{props.info.first_name} {props.info.last_name.charAt(0) + "."} {QulifiedIcon}</p>
        <div className={`row d-table d-sm-flex`}>
          {renderInfoRow("teaches", TeachesIcon, props.info.teaches.join(", "))}
          {props.info.speaks && renderInfoRow("speaks", SpeaksIcon, props.info.speaks.join(", "))}
          {renderInfoRow("education", EducationIcon, props.info.educations?.map((education, index) => (
            <div key={education.degree + index} className="mb-3 pb-1">
              <div className="teacher-info__degree booking-text booking-text--bold">{education.degree}</div>
              <div className="teacher-info__institution booking-text">{education.institution}</div>
            </div>
          )))}
          {renderInfoRow("experience", ExperienceIcon, props.info.experiences?.map(experience => (
            <div key={experience.date_of_beginning + experience.position} className="mb-3">
              <div className="teacher-info__position booking-text--bold">{experience.position}</div>
              <div className="teacher-info__place-of-work">{experience.place_of_work}</div>
              <div className="teacher-info__date-of-beginning d-inline booking-text--gray">{experience.date_of_beginning + " - "}</div>
              <div className="teacher-info__date-of-end d-inline booking-text--gray">{experience.date_of_end || I18n.t("booking.present")}</div>
            </div>
          )))}
        </div>
      </div>
    </div>
  )
}

TeacherInfo.propTypes = {
  id: PropTypes.string,
  info: PropTypes.shape({
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    teaches: PropTypes.arrayOf(PropTypes.string),
    speaks: PropTypes.arrayOf(PropTypes.string),
    experiences: PropTypes.arrayOf(PropTypes.shape({
      position: PropTypes.string,
      place_of_work: PropTypes.string,
      date_of_beginning: PropTypes.string,
      date_of_end: PropTypes.string
    })),
    educations: PropTypes.arrayOf(PropTypes.shape({
      degree: PropTypes.string,
      institution: PropTypes.string
    }))
  })
}

export default TeacherInfo;

const TeachesIcon = (
  <svg className="align-center mr-1" xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18" fill="none">
    <ellipse cx="13" cy="11.8166" rx="6" ry="5.5" fill="#BBBBBB" />
    <path d="M13.2052 0.860629L13 0.768297L12.7948 0.860629L2.79482 5.36063L1.78157 5.81659L2.79482 6.27255L12.7948 10.7726L13 10.8649L13.2052 10.7726L23.2052 6.27255L24.2184 5.81659L23.2052 5.36063L13.2052 0.860629Z" fill="#BBBBBB" stroke="white" />
  </svg>
)

const SpeaksIcon = (
  <svg className="align-center mr-1" width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0.281738C0.895431 0.281738 0 1.17717 0 2.28174V10.2817C0 11.3863 0.89543 12.2817 2 12.2817H15C16.1046 12.2817 17 11.3863 17 10.2817V8.59114L21 6.28174L17 3.97234V2.28174C17 1.17717 16.1046 0.281738 15 0.281738H2Z" fill="#BBBBBB" />
    <circle cx="4" cy="6.28174" r="1" fill="white" />
    <circle cx="8" cy="6.28174" r="1" fill="white" />
    <circle cx="12" cy="6.28174" r="1" fill="white" />
  </svg>
)

const EducationIcon = (
  <svg className="align-center mr-1" width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 19.9997H14L17 17V1H4L1 4V19.9997Z" fill="#BBBBBB" />
    <path d="M1 4V19.9997H14" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 1H4L1 4H14V20L17 17V1Z" stroke="white" strokeLinejoin="round" />
    <path d="M4 4H8V11L6 9.5L4 11V4Z" fill="white" stroke="white" strokeLinejoin="round" />
  </svg>


)

const ExperienceIcon = (
  <svg className="align-center mr-1" width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 7C3.55228 7 4 6.55228 4 6V5H2V6C2 6.55228 2.44772 7 3 7Z" fill="white" />
    <path d="M0 2C0 0.895431 0.895431 0 2 0H15C16.1046 0 17 0.895431 17 2V10C17 11.1046 16.1046 12 15 12H2C0.89543 12 0 11.1046 0 10V2Z" fill="#BBBBBB" />
    <path d="M11 10V10C11 8.89543 10.1046 8 9 8H8C6.89543 8 6 8.89543 6 10V10" stroke="#C4C4C4" strokeWidth="2" />
    <path d="M13 7C13.5523 7 14 6.55228 14 6V5H12V6C12 6.55228 12.4477 7 13 7Z" fill="white" />
    <path d="M4 7C4.55228 7 5 6.55228 5 6V5H3V6C3 6.55228 3.44772 7 4 7Z" fill="white" />
    <line y1="4.5" x2="17" y2="4.5" stroke="white" />
  </svg>

)

const QulifiedIcon = (
  <svg className="align-bottom mr-1" width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13.3928V2.91318C4.46544 3.46305 8.00031 2.97882 11.2592 1.50223C14.5181 2.97882 18.053 3.46305 21.5184 2.91318V13.3928C21.5184 20.1546 16.7957 25.3842 11.2592 25.3842C5.72276 25.3842 1 20.1546 1 13.3928Z" stroke="#00C566" strokeWidth="2" />
    <path d="M5.19629 13.7258L9.60549 17.7232L17.3216 7.33008" stroke="#00C566" strokeWidth="2" />
  </svg>
)

