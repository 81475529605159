import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import CreateReview from '../UI/CreateReview';
import { SHOW_REVIEW_MODAL_EVENT } from '../../config/events';

const ReviewModal = props => {
  const [show, setShow] = useState(props.open);

  window.addEventListener(SHOW_REVIEW_MODAL_EVENT, (e) => {
    if (!e.detail || e.detail == props.teacherInfo.teacherId) {
      setShow(true)
    }
  });

  return (
    <Modal
      component={
        <CreateReview info={{ ...props.teacherInfo, teachers: props.teachers }} />
      }
      key={props.teacherInfo.id}
      open={!!props.teacherInfo && show}
      onClose={() => setShow(false)}
    />
  )
}

ReviewModal.propTypes = {
  open: PropTypes.bool
}

ReviewModal.defaultProps = {
  open: false
}

export default ReviewModal;
