const secondRuleRegExp = /^[A-Z0-9._%+-@]+\.[A-Z]+/i;

const emailValidationId = "#email_validation";
const loginButtonId = "#login_button";
const firstRuleId = "#first_rule";
const secondRuleId = "#second_rule";
const thirdRuleId = "#third_rule";

const messageVisibleClass = "help-container__message--visible";
const invalidTextInputClass = "text-input--invalid";

let rulesArray = [
  {
    id: thirdRuleId,
    validationFunction: thirdRuleValidateFunction
  },
  {
    id: firstRuleId,
    validationFunction: firstRuleValidateFunction
  },
  {
    id: secondRuleId,
    validationFunction: secondRuleValidateFunction
  }
]

function firstRuleValidateFunction(emailValidationVal) {
  return emailValidationVal.indexOf("@") === -1;
}

function secondRuleValidateFunction(emailValidationVal) {
  return !secondRuleRegExp.test(emailValidationVal);
}

function thirdRuleValidateFunction(emailValidationVal) {
  return !secondRuleRegExp.test(emailValidationVal) && (emailValidationVal.indexOf("@") === -1); 
}

$(document).ready(() => {
  

  var emailValidation = $(emailValidationId);
  var loginButton = $(loginButtonId);
  
  emailValidation.change(() => {

    var emailValidationVal = emailValidation.val();

    emailValidation.removeClass(invalidTextInputClass);
    $(firstRuleId).removeClass(messageVisibleClass);
    $(secondRuleId).removeClass(messageVisibleClass);
    $(thirdRuleId).removeClass(messageVisibleClass);
    loginButton.prop("disabled", false);

    for(var i=0; i < rulesArray.length; i++) {
      if(rulesArray[i].validationFunction(emailValidationVal)) {
        $(rulesArray[i].id).addClass(messageVisibleClass);
        emailValidation.addClass(invalidTextInputClass);
        loginButton.prop("disabled", true);
        break;
      }
    }
  });
});