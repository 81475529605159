$(document).ready(() => {
  let year = new Date().getFullYear();
  $('.footer__design-text').replaceWith(`<p class = "align-right footer__design-text">
  © ${year}, Rootalky
  </p>`);

  const getLangFromHost = () => {
    const hostParts = location.host.split('.');
    if (hostParts.length === 2) {
      return 'en';
    }
    return hostParts[0];
  }
  $('#change_language').val(getLangFromHost());
  $('#change_language').change((e) => {
    const hostParts = location.host.split('.');
    const targetLang = e.target.value;
    if (hostParts.length === 2) {
      if (targetLang != "en") {
        hostParts.unshift(targetLang);
      }
    } else {
      if (targetLang === "en") {
        hostParts.shift();
      }
      else {
        hostParts[0] = targetLang;

      }
    }
    const newHost = hostParts.join('.');
    location.host = newHost;
  });
});
