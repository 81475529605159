import React from 'react';
import I18n from '../../i18n-js/index.js.erb';
import starIcon from '../../../assets/images/rating-star.svg';
import starIconGray from '../../../assets/images/rating-star-gray.svg';
import paginationArrowIcon1 from '../../../assets/images/pagination-arrow-1.svg';
import paginationArrowIcon2 from '../../../assets/images/pagination-arrow-2.svg';
import { fetch } from 'whatwg-fetch';
import Button from './Button';
import Modal from './Modal';
import CreateReview from './CreateReview';

class ReviewsCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 0,
      showCreateReview: false
    };
  }

  componentWillReceiveProps = ({ reviews }) => {
    if (reviews !== this.props.reviews) {
      this.setState({ activePage: 0 })
    }
  }

  componentDidMount() {
    
  }

  setActivePage(page) {
    this.setState({ activePage: page });
  }

  setPrevPage() {
    this.setState({
      activePage: Math.max(this.state.activePage - 1, 0),
    });
  }

  setNextPage(page) {
    this.setState({
      activePage: Math.min(this.state.activePage + 1, 3),
    });
  }

  render() {
    const reviews = [...this.props.reviews.slice(0, 4), ...Array(4 - this.props.reviews.slice(0, 4).length)].map((review, index) => {
      return (
          <div key={index} className="col-12 review-card-wrapper">
            { 
              review &&
                <div className="review-card">
                  <h3 className="review-card__title">{review.title || 'Review'}</h3>
                  <div className="d-flex align-items-center">
                    {
                      Array.from(Array(5).keys()).map(index => (
                        <img key={index} className="review-card__rating-star" src={index >= review.star_rating ? starIconGray : starIcon} />
                      ))
                    }
                    <p className="review-card__rating">{review.star_rating}/5</p>
                  </div>
                  <p className="review-card__body">
                    {review.body}
                  </p>
                  <p className="review-card__timestamp">{review.student_name} <span className="u-cs-txt--gray">|</span> {new Date(review.created_at).toDateString()}</p>
                </div>
            }
          </div>
      );
    });

    return (
      <div className="container mt-5">
        {/* <Button
          label="Leave a review"
          className="review-card review-card--no-reviews mx-auto w-100 d-block p-2 mb-2"
          onClick={() => this.setState({ showCreateReview: true })}
        /> */}
        <div className="row landing-page__user-comments-cards-container">
          <div
            className={`m-0 landing-page__user-comments-cards landing-page__user-comments-cards--${this.state.activePage}`}
          >
            {reviews}
          </div>
        </div>
        <div className="landing-page__user-comments-cards-pagination">
          <button
            className="landing-page__user-comments-cards-arrow-pagination-container"
            onClick={() => this.setPrevPage()}
            disabled={this.state.activePage === 0}
          >
            <img
              className="landing-page__user-comments-cards-left-arrow-pagination"
              src={paginationArrowIcon2}
            ></img>
          </button>
          <button
            className={`landing-page__user-comments-cards-page-pagination ${
              this.state.activePage == 0
                ? 'landing-page__user-comments-cards-page-pagination--active'
                : ''
            }`}
            onClick={() => this.setActivePage(0)}
            disabled={this.props.reviews.length < 1}
          ></button>
          <button
            className={`landing-page__user-comments-cards-page-pagination ${
              this.state.activePage == 1
                ? 'landing-page__user-comments-cards-page-pagination--active'
                : ''
            }`}
            onClick={() => this.setActivePage(1)}
            disabled={this.props.reviews.length < 2}
          ></button>
          <button
            className={`landing-page__user-comments-cards-page-pagination ${
              this.state.activePage == 2
                ? 'landing-page__user-comments-cards-page-pagination--active'
                : ''
            }`}
            onClick={() => this.setActivePage(2)}
            disabled={this.props.reviews.length < 3}
          ></button>
          <button
            className={`landing-page__user-comments-cards-page-pagination ${
              this.state.activePage == 3
                ? 'landing-page__user-comments-cards-page-pagination--active'
                : ''
            }`}
            onClick={() => this.setActivePage(3)}
            disabled={this.props.reviews.length < 4}
            ></button>
          <button
            className="landing-page__user-comments-cards-arrow-pagination-container"
            onClick={() => this.setNextPage()}
            disabled={this.props.reviews.length <= this.state.activePage + 1}
          >
            <img
              className="landing-page__user-comments-cards-right-arrow-pagination"
              src={paginationArrowIcon1}
            ></img>
          </button>
        </div>
        <Modal
          component={
            <CreateReview teachers={{teachers: this.props.teachers}} />
          }
          open={!!this.state.showCreateReview}
          onClose={() => this.setState({ showCreateReview: false })}
        />
      </div>
    );
  }
}

export default ReviewsCarousel;
