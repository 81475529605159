import React, { useState } from 'react';
import I18n from '../../i18n-js/index.js.erb';
import courseSquareImage from '../../../assets/images/course_square.png';
import courseTriangleImage from '../../../assets/images/course_triangle.png';
import courseCircleImage from '../../../assets/images/course_circle.png';
import redVectorImage from '../../../assets/images/red-vector.svg';

const levelsLocalesPath = 'components.knowledge_levels.levels';

export default (props) => {
  const [currentDiff, setCurrentDiff] = useState('beginner');
  const [currentLevel, setCurrentLevel] = useState('a1');

  const getCurrentPage = () => `${currentDiff}_${currentLevel}`;

  const standartLevels = {
    french: {
      beginner: 'a1',
      intermediate: 'b1',
      advanced: 'b2c1',
    },
    german: {
      beginner: 'a1',
      intermediate: 'b1',
      advanced: 'c1',
    },
  };

  const getList = (strokeLink, listLength, listType) => {
    const listStrokes = [];
    for (let i = 1; i <= listLength; i++) {
      listStrokes.push(I18n.t(`${strokeLink}.list_item_${i}`));
    }

    return (
      <ul className="course-page__list">
        {listStrokes.map((stroke, index) => (
          <li
            className="course-page__list-item course-page__list-item--knowledge"
            key={index}
          >
            {listType === 0 ? (
              <span className="course-page__list-item-number">{index + 1}</span>
            ) : (
              <img src={redVectorImage} alt="" className="align-self-start" />
            )}
            <span className="course-page__list-item-text">{stroke}</span>
          </li>
        ))}
      </ul>
    );
  };

  const getTitle = text => (
    <p className="course-page__knowledge-text course-page__knowledge-text--title">
      {text}
    </p>
  );

  const getText = text => (
    <p
      className="course-page__knowledge-text course-page__knowledge-text--first"
      dangerouslySetInnerHTML={{ __html: text }}
    ></p>
  );

  const getLocale = () =>
    `${levelsLocalesPath}.${props.currentLangPage}.${getCurrentPage()}`;

  const getLevel = (level, title) => (
    <div
      className={`
        course-page__left-box-level-block
        ${currentLevel === level && 'course-page__left-box-level-block--active'}
      `}
      onClick={() => {
        handleChangeLevel(level);
      }}
    >
      {level.length > 2 ? (
        <p className="course-page__left-box-level-text course-page__left-box-level-text--bold">
          {level.substr(0, 2).toUpperCase()}
          <span className="course-page__left-box-level-text course-page__left-box-level-text--bold course-page__left-box-level-text--common">
            &nbsp;-&nbsp;
          </span>
          {level.substr(2, 3).toUpperCase()}
        </p>
      ) : (
        <p className="course-page__left-box-level-text course-page__left-box-level-text--bold">
          {level.toUpperCase()}
        </p>
      )}
      <p className="course-page__left-box-level-text">{title}</p>
    </div>
  );

  const levelsCounts = {
    french: {
      beginner: 4,
      intermediate: 3,
      advanced: 3,
    },
    german: {
      beginner: 2,
      intermediate: 2,
      advanced: 2,
    },
  };

  const levels = {
    french: {
      beginner: (
        <>
          {getLevel('a1',I18n.t(`components.knowledge_levels.levels.french.Starter`))}
          {getLevel('a1a2', I18n.t(`components.knowledge_levels.levels.french.Learner`))}
          {getLevel('a2', I18n.t(`components.knowledge_levels.levels.french.Elementary`))}
          {getLevel('a2b1', I18n.t(`components.knowledge_levels.levels.french.Novice`))}
        </>
      ),
      intermediate: (
        <>
          {getLevel('b1',I18n.t(`components.knowledge_levels.levels.french.Starter`))}
          {getLevel('b1b2', I18n.t(`components.knowledge_levels.levels.french.Learner`))}
          {getLevel('b2', I18n.t(`components.knowledge_levels.levels.french.Elementary`))}
        </>
      ),
      advanced: (
        <>
          {getLevel('b2c1',I18n.t(`components.knowledge_levels.levels.french.Starter`))}
          {getLevel('c1', I18n.t(`components.knowledge_levels.levels.french.Learner`))}
          {getLevel('c2', I18n.t(`components.knowledge_levels.levels.french.Elementary`))}
        </>
      ),
    },
    german: {
      beginner: (
        <>
          {getLevel('a1',I18n.t(`components.knowledge_levels.levels.german.Starter`))}
          {getLevel('a2',I18n.t(`components.knowledge_levels.levels.german.Elementary`))}
        </>
      ),
      intermediate: (
        <>
          {getLevel('b1',I18n.t(`components.knowledge_levels.levels.german.Intermediate`))}
          {getLevel('b2',I18n.t(`components.knowledge_levels.levels.german.UpperIntermediate`))}
        </>
      ),
      advanced: (
        <>
          {getLevel('c1',I18n.t(`components.knowledge_levels.levels.german.Expert`))}
          {getLevel('c2',I18n.t(`components.knowledge_levels.levels.german.Master`))}
        </>
      ),
    },
  };

  const levelsContents = {
    french: {
      beginner_a1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 4, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getList(`${getLocale()}.thirdth`, 5, 1)}
        </>
      ),
      beginner_a1a2: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 5, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.adults.title`))}
          {getList(`${getLocale()}.thirdth.adults`, 3, 1)}
          {getText(I18n.t(`${getLocale()}.thirdth.public`))}
        </>
      ),
      beginner_a2: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 3, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.adults.title`))}
          {getList(`${getLocale()}.thirdth.adults`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
      beginner_a2b1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 3, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.adults.title`))}
          {getList(`${getLocale()}.thirdth.adults`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
      intermediate_b1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 4, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getList(`${getLocale()}.thirdth`, 4, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
      intermediate_b1b2: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 4, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getList(`${getLocale()}.thirdth`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
      intermediate_b2: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 4, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getList(`${getLocale()}.thirdth`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
      advanced_b2c1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 3, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.adults.title`))}
          {getList(`${getLocale()}.thirdth.adults`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
      advanced_c1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 6, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getList(`${getLocale()}.thirdth`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
      advanced_c2: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 5, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.adults.title`))}
          {getList(`${getLocale()}.thirdth.adults`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.fourth.title`))}
          {getText(I18n.t(`${getLocale()}.fourth.text`))}
        </>
      ),
    },
    german: {
      beginner_a1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 3, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getList(`${getLocale()}.thirdth`, 5, 1)}
        </>
      ),
      beginner_a2: (
        <>
          {getList(`${getLocale()}.first`, 2, 0)}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 5, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.first.title`))}
          {getList(`${getLocale()}.thirdth.first`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.second.title`))}
          {getList(`${getLocale()}.thirdth.second`, 3, 1)}
        </>
      ),
      intermediate_b1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 4, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.first.title`))}
          {getList(`${getLocale()}.thirdth.first`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.second.title`))}
          {getList(`${getLocale()}.thirdth.second`, 4, 1)}
        </>
      ),
      intermediate_b2: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 7, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.first.title`))}
          {getList(`${getLocale()}.thirdth.first`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.second.title`))}
          {getList(`${getLocale()}.thirdth.second`, 3, 1)}
        </>
      ),
      advanced_c1: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 6, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getTitle(I18n.t(`${getLocale()}.thirdth.first.title`))}
          {getList(`${getLocale()}.thirdth.first`, 3, 1)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.second.title`))}
          {getList(`${getLocale()}.thirdth.second`, 3, 1)}
        </>
      ),
      advanced_c2: (
        <>
          {getText(I18n.t(`${getLocale()}.first`))}
          {getTitle(I18n.t(`${getLocale()}.second.title`))}
          {getList(`${getLocale()}.second`, 4, 0)}
          {getTitle(I18n.t(`${getLocale()}.thirdth.title`))}
          {getList(`${getLocale()}.thirdth`, 3, 1)}
        </>
      ),
    },
  };

  const handleChangeLevel = (level) => {
    if (levelsContents[props.currentLangPage][`${currentDiff}_${level}`]) {
      setCurrentLevel(level);
    }
  };

  const handleChangeDiff = (diff) => {
    if (levels[props.currentLangPage][diff]) {
      setCurrentDiff(diff);
      setCurrentLevel(standartLevels[props.currentLangPage][diff]);
    }
  };

  return (
    <div className="course-page__side-boxes-wrapper">
      <div className="course-page__side-box course-page__side-box--left">
        <div className="course-page__left-box course-page__left-box--left-side">
          <div
            className={`
              course-page__left-box-diff-block
              ${
                currentDiff === 'beginner' &&
                'course-page__left-box-diff-block--active'
              }
            `}
            onClick={() => {
              handleChangeDiff('beginner');
            }}
          >
            <img
              src={courseTriangleImage}
              alt=""
              className="course-page__left-box-image"
            />
            <p className="course-page__left-box-image-text">{I18n.t(`components.knowledge_levels.levels.french.Beginner`)}</p>
          </div>
          <div
            className={`
              course-page__left-box-diff-block
              ${
                currentDiff === 'intermediate' &&
                'course-page__left-box-diff-block--active'
              }
            `}
            onClick={() => {
              handleChangeDiff('intermediate');
            }}
          >
            <img
              src={courseSquareImage}
              alt=""
              className="course-page__left-box-image"
            />
            <p className="course-page__left-box-image-text">{I18n.t(`components.knowledge_levels.levels.french.Intermediate`)}</p>
          </div>
          <div
            className={`
              course-page__left-box-diff-block
              ${
                currentDiff === 'advanced' &&
                'course-page__left-box-diff-block--active'
              }
            `}
            onClick={() => {
              handleChangeDiff('advanced');
            }}
          >
            <img
              src={courseCircleImage}
              alt=""
              className="course-page__left-box-image"
            />
            <p className="course-page__left-box-image-text">{I18n.t(`components.knowledge_levels.levels.french.Advanced`)}</p>
          </div>
        </div>
        <div className="course-page__left-box-line-wrapper" data-count={levelsCounts[props.currentLangPage][currentDiff]}>
          <div className="course-page__left-box-line"></div>
        </div>
        <div className="course-page__left-box course-page__left-box--right-side">
          {levels[props.currentLangPage][currentDiff]}
        </div>
      </div>
      <div className="course-page__side-box course-page__side-box--right">
        {levelsContents[props.currentLangPage][getCurrentPage()]}
      </div>
    </div>
  );
};
