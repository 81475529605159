var queryParams = {}

function handleURLFilter() {
  var checkboxLevels = $('input.levels');
  var checkboxAllLevels = $('input.all_levels')

  var checkboxLanguages = $('input.languages');
  var checkboxAllLanguages = $('input.all_languages')


  if (checkboxAllLevels.is(':checked')) {
    queryParams['levels'] = 'all';
  } else {
    var checkArr = [];
    checkboxLevels.each(function() {
      if ($(this).is(':checked')) {
        checkArr.push($(this).val());
      }
    });
    queryParams['levels'] = checkArr.join(',');
  }

  if (checkboxAllLanguages.is(':checked')) {
    queryParams['languages'] = 'all';
  } else {
    var checkArr = [];
    checkboxLanguages.each(function() {
      if ($(this).is(':checked')) {
        checkArr.push($(this).val());
      }
    });
    queryParams['languages'] = checkArr.join(',');
  }

  console.log("====query", queryParams)

  var options = {
    traditional: true // use traditional encoding for arrays (e.g. foo[]=1&foo[]=2)
  };
  var query =  $.param(queryParams, options)

  var newUrl = `${location.pathname}?${query.toString()}`
  // console.log("====newUrl", newUrl)
  window.location.href = newUrl;
  // window.location.reload();
}

function handleGroupClassFilters(checkAllCheckbox, checkboxes) {
  // when the "All" checkbox is clicked
  $(checkAllCheckbox).click(function() {
    // if it is checked, check all the other checkboxes and disable them
    if ($(this).is(':checked')) {
      $(checkboxes).prop('checked', true);
    }
    // otherwise, uncheck all the other checkboxes and enable them
    else {
      $(checkboxes).prop('checked', false);
    }
    handleURLFilter();
  });

  // when any of the other checkboxes is clicked
  $(checkboxes).click(function() {
    // if the "All" checkbox is checked and this checkbox is being unchecked
    if ($(checkAllCheckbox).is(':checked') && !$(this).is(':checked')) {
      // uncheck the "All" checkbox and enable all checkboxes
      $(checkAllCheckbox).prop('checked', false);
    }
    // if all checkboxes are checked, check the "All" checkbox and disable all checkboxes except the "All" checkbox
    else if ($(checkboxes).not(checkAllCheckbox).not(':checked').length === 0) {
      $(checkAllCheckbox).prop('checked', true);
    }
    // otherwise, enable all checkboxes
    else {
      $(checkAllCheckbox).prop('checked', false);
    }
    handleURLFilter();
  });
}

$(document).ready(() => {
  var checkboxLevels = $('input.levels');
  var checkboxAllLevels = $('input.all_levels')

  var checkboxLanguages = $('input.languages');
  var checkboxAllLanguages = $('input.all_languages')
  handleGroupClassFilters(checkboxAllLevels, checkboxLevels)
  handleGroupClassFilters(checkboxAllLanguages, checkboxLanguages)
})
