import ReactStars from "react-rating-stars-component";
import React from 'react';
import { render } from "react-dom";
import {fetch} from 'whatwg-fetch';
import defaultAvatar from '../../../assets/images/tokky-happy.png';

class CreateReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ratingStar: 0,
      teacherId: '',
      title: '',
      description: '',
      teachers: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(typeof this.props.info != 'undefined') {
      this.setState({teachers: [this.props.info.teachers]})
    }

  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      ratingStar,
      teacherId,
      title,
      description
    } = this.state;

    let review_params = JSON.stringify({
      title: title,
      body: description,
      star_rating: ratingStar
    })

    fetch("/teachers/"+teacherId+"/reviews", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: review_params
    })
    .then(() => {
      window.location.reload()
    })
    .then(data => {
    })
  }

  render() {
    const { teachers } = this.state;
    let teacherOptions = [<option key='default' value="Choose your teacher">Choose your teacher</option>];
    if(typeof this.props.info != 'undefined') {
      Object.keys(this.props.info.teachers).map(([key, teacher]) => (
        teacherOptions.push(<option key={key} value={this.props.info.teachers[key]['_id']['$oid']}>
          {this.props.info.teachers[key]['first_name'] + ' ' + this.props.info.teachers[key]['last_name']}
        </option>)
      ));
    }

    const TeacherSelectBox = (
      <div className="form-group">
        <label htmlFor="title">Your Teacher</label>
        <select name='teacherId' value={this.state.teacherId} onChange={this.handleChange} className="form-control">
          {teacherOptions}
        </select>
      </div>
    );

    const ratingChanged = (newRating) => {
      this.setState({ratingStar: newRating})
    };

    return (
      <div className="row review-info">
        <div className="col-md-6 col-12">
          <img
            src={defaultAvatar}
            className="review-info__image"
          />
        </div>
        <div className="col-md-6 col-12">
          <h3>Leave your review</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                activeColor="#ffd700"              />
            </div>
            {TeacherSelectBox}
            <div className="form-group">
              <label htmlFor="title">Review Title</label>
              <input type="text" name="title" defaultValue={this.state.title} onChange={this.handleChange} className="form-control" placeholder="Enter your title" />
            </div>
            <div className="form-group">
              <label htmlFor="description">Write review</label>
              <textarea name="description" defaultValue={this.state.description} onChange={this.handleChange} className="form-control" placeholder="Write your review" />
            </div>
            <div className="form-group">
              <input type="submit" value="Publish Review" className="button contact-us-field__button" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateReview;
