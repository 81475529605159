import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import Button from '../UI/Button';

const Program = props => {
  const [donateCount, writeDonateCount] = useState(1);
  const setDonateCount = count => {
    if ((count > 0) && (count <= props.available_stars)) {
      writeDonateCount(count);
    }
  };
  const incrementDonateCount = () => setDonateCount(donateCount + 1);
  const decrementDonateCount = () => setDonateCount(donateCount - 1);

  let starsArray = [];
  for (let i = 0; i < props.available_stars; i++) {
    starsArray.push(i);
  }

  if (props.details) return (
    <div className="details-page__program-content">
      <div className="details-page__program-img-bordered-wrapper">
        <div className="details-page__program-img-wrapper">
          <img src={props.image} className="details-page__program-img"/>
        </div>
      </div>
      <div className="details-page__program-info">
        <p className="details-page__program-name">{props.program.name}</p>
        <p className="details-page__program-description" dangerouslySetInnerHTML={{__html: props.program.description}}></p>
        <div className="details-page__info-bottom">
          <div className="details-page__money-block">
            <div className="details-page__money-raised">
              <p className="details-page__money-count">$ {props.program.raised_money}</p>
              <p className="details-page__money-subtitle">{I18n.t('donations.details.raised')}</p>
            </div>
            <div className="details-page__money-progress">
              <div className="details-page__progress-container">
                <div className="details-page__progress" style={{width: `${props.program.raised_money / props.program.target_money * 100}%`}}></div>
              </div>
            </div>
            <div className="details-page__money-target">
              <p className="details-page__money-count">$ {props.program.target_money}</p>
              <p className="details-page__money-subtitle">{I18n.t('donations.details.target')}</p>
            </div>
          </div>
          <div className={`details-page__donate-stars ${!props.userSignedIn && 'd-none'}`}>
            <p className="stars-count-selector__input-value">
              {donateCount}
              <span className="stars-count-selector__input-value--additional">
                /{props.available_stars}
              </span>
            </p>
            <div className="donation__stars-count-selector stars-count-selector">
              <button
                type="button"
                disabled={donateCount == 1}
                onClick={decrementDonateCount}
                className="stars-count-selector__btn"
              >-</button>
              <input
                type="number"
                name="donation_program[stars_count]"
                value={donateCount}
                min={1}
                max={props.available_stars}
                onChange={e => setDonateCount(e.target.value)}
                className="stars-count-selector__input"
              ></input>
              <button
                type="button"
                disabled={donateCount == props.available_stars}
                onClick={incrementDonateCount}
                className="stars-count-selector__btn"
                >+</button>
            </div>
          </div>
          {
            props.userSignedIn 
            ? <Button submit className="details-page__donate-btn" label={I18n.t('donations.details.donate_stars')} />
            : <a href="/users/sign_in" className="details-page__donate-btn button">{I18n.t('donations.details.donate_stars')}</a>
          }          
        </div>
      </div>
    </div>
  );

  return (
    <div className="donation__card-wrapper">
      <div className="donation__card-img-wrapper">
        <img className="donation__card-img" src={props.image} />
      </div>
      <div className="donation__card">
        <h3 className="donation__card-title">{ props.name }</h3>
        <p className="donation__card-description" dangerouslySetInnerHTML={{__html: props.description,}}></p>
        <span className="donation__progress-value">{ Math.round(props.raised_money * 100 / props.target_money) }%</span>
        <progress className="donation__progress" value={ props.raised_money } max={ props.target_money }></progress>
        <div className="donation__statistic">
          <div className="donation__statistic-block donation__statistic-block--raised">
            <p className="donation__raised-count">${ props.raised_money }</p>
            <p className="donation__raised-title">raised</p>
          </div>
          <div className="donation__statistic-block donation__statistic-block--target">
            <p className="donation__target-count">${ props.target_money }</p>
            <p className="donation__target-title">target</p>
          </div>
        </div>
      </div>

      <div className="donation__dancing-stars">
        {
          starsArray.map((i) => (
            i < donateCount 
              ? <div className="donation__dancing-star donation__dancing-star--active" key={i}></div>
              : <div className="donation__dancing-star" key={i}></div>
          ))
        }
      </div>
      <div className="donation__card-bottom">
        <p className="stars-count-selector__input-value">
          {donateCount}
          <span className="stars-count-selector__input-value--additional">
            /{props.available_stars}
          </span>
        </p>
        <div className="donation__stars-count-selector stars-count-selector">
          <button
            type="button"
            disabled={donateCount == 1}
            onClick={decrementDonateCount}
            className="stars-count-selector__btn"
          >-</button>
          <input
            type="number"
            name="donation_program[stars_count]"
            value={donateCount}
            min={1}
            max={props.available_stars}
            onChange={e => setDonateCount(e.target.value)}
            className="stars-count-selector__input"
          ></input>
          <button
            type="button"
            disabled={donateCount == props.available_stars}
            onClick={incrementDonateCount}
            className="stars-count-selector__btn"
            >+</button>
        </div>

        <Button submit fullWidth label={`Donate ${donateCount} Star${donateCount > 1 ? 's' : ''}`} />
      </div>  
    </div>
  );
}

Program.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image_url: PropTypes.string,
  raised_money: PropTypes.number,
  target_money: PropTypes.number,
  available_stars: PropTypes.number
}

export default Program;
