$(document).ready(() => {
  const input = $(".we-are-hiring-page__feedback-btn--input");
  const label = $(".we-are-hiring-page__feedback-btn--label");

  input.change(() => {
    let fileName = input.val().split("\\").pop();
    if (fileName) {
      label.text(fileName);
    }

  });
});